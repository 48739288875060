import React from 'react'
import GitlabLogo from './GitlabLogo'

export default function RepoLink({ name, docsUrl }) {
  const repoUrl = `https://gitlab.com/seaofvoices/${name}`
  return (
    <>
      <div style={{ display: 'flex' }}>
        <a href={repoUrl} className='repo-link' target='_blank' rel='noreferrer'>
          <GitlabLogo alt='' height='36' style={{ alignSelf: 'center' }}/>
          <p>Gitlab</p>
        </a>
        {
          docsUrl && <a href={docsUrl} className='repo-docs' target='_blank' rel='noreferrer'>
            Documentation
          </a>
        }
        <br/>
      </div>
    </>
  )
}
