import React from 'react'
import { graphql, StaticQuery } from 'gatsby'

export default function GitlabLogo({ ...props }) {
  return (
    <StaticQuery
      query={graphql`
        query ImageQuery {
          file(relativePath: { eq: "gitlab-icon-rgb.svg" }) {
            publicURL
          }
        }
      `}
      render={data => (
        <img src={data.file.publicURL} alt='Gitlab logo' {...props}/>
      )}
    />
  )
}
