import React from 'react'
import { graphql, Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Layout from './layout'
import { MDXProvider } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import RepoLink from './RepoLink'

const shortcodes = {
  RepoLink,
  Spacer: () => (<br/>)
}

export default function Template({
  data,
}) {
  const { mdx, background } = data
  return (
    <BackgroundImage
      fluid={[
        'linear-gradient(90deg,rgba(19,21,25,.9),rgba(19,21,25,.8))',
        background.childImageSharp.fluid,
      ]}
    >
      <Layout title={mdx.frontmatter.title}>
        <div>
          <div style={{maxWidth: '800px'}}>
            <MDXProvider components={shortcodes}>
              <MDXRenderer>
                {mdx.body}
              </MDXRenderer>
            </MDXProvider>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Link to='/' className='back-button'>Back</Link>
            </div>
          </div>
        </div>
      </Layout>
    </BackgroundImage>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    mdx(slug: { eq: $slug }) {
      body
      frontmatter {
        title
      }
    }
    background: file(relativePath: { eq: "background.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
